<script>
import {
  FacebookIcon,
  TwitterIcon,
  InstagramIcon,
  LinkedinIcon,
  ArrowRightCircleIcon
} from "vue-feather-icons";

export default {
  props: {
    navbarcolor: {
      type: Boolean,
      default: false
    }
  },
  components: {
    FacebookIcon,
    TwitterIcon,
    InstagramIcon,
    LinkedinIcon,
    ArrowRightCircleIcon
  },
  mounted: () => {
    window.onscroll = function() {
      onwindowScroll();
    };
    var navbar = document.getElementById("navbar");
    function onwindowScroll() {
      if (
        document.body.scrollTop > 53 ||
        document.documentElement.scrollTop > 53
      ) {
        navbar.classList.add("nav-sticky");
      } else {
        navbar.classList.remove("nav-sticky");
      }
    }
  },
  methods: {
    /**
     * Toggle menu
     */
    toggleMenu() {
      document.getElementById("navbarCollapse").classList.toggle("show");
    }
  }
};
</script>

<template>
  <!--Navbar Start-->
  <nav
    class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark"
    id="navbar"
    :class="{'navbar-light': navbarcolor === true}"
  >
    <div class="container">
      <!-- LOGO -->
      <a class="navbar-brand logo" href="/">
        <img src="@/assets/images/logo-dark.png" alt class="logo-dark" height="48" />
        <img src="@/assets/images/logo-light.png" alt class="logo-light" height="48" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        @click="toggleMenu()"
        aria-label="Toggle navigation"
      >
        <i class="mdi mdi-menu"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav mx-auto navbar-center" id="mySidenav" v-scroll-spy-active>
          <li class="nav-item">
            <a href="javascript: void(0);" v-scroll-to="'#home'" class="nav-link">Inicio</a>
          </li>
          <li class="nav-item">
            <a
              href="javascript: void(0);"
              v-scroll-to="{ el: '#nosotros', offset: 2}"
              class="nav-link"
            >Nosotros</a>
          </li>
          <li class="nav-item">
            <a
              href="javascript: void(0);"
              v-scroll-to="{ el:'#servicios', offset: 2}"
              class="nav-link"
            >Servicios</a>
          </li>
          <li class="nav-item">
            <a
              href="javascript: void(0);"
              v-scroll-to="{ el:'#clients', offset: 2}"
              class="nav-link"
            >Clientes</a>
          </li>
          <!-- <li class="nav-item">
            <a
              href="javascript: void(0);"
              v-scroll-to="{ el:'#blog', offset: -1}"
              class="nav-link"
            >Blog</a>
          </li> -->
          <li class="nav-item">
            <a
              href="javascript: void(0);"
              v-scroll-to="{ el:'#contact', offset: 2}"
              class="nav-link"
            >Contacto</a>
          </li>
        </ul>
        <ul class="navbar-nav navbar-center">
          <li class="nav-item">
            <a href="javascript: void(0);" class="nav-link" v-b-modal.login-modal>Iniciar Sesión</a>
          </li>
          <!-- <li class="nav-item">
            <a href="javascript: void(0);" class="nav-link" v-b-modal.register-modal>Registrate</a>
          </li> -->
        </ul>
      </div>
    </div>
    <b-modal id="login-modal" hide-header hide-footer centered>
      <div class="text-center">
        <!-- LOGO -->
        <span class="navbar-brand logo mb-4">
            <img src="@/assets/images/logo-dark.png" alt class="logo-dark" height="48" />
        </span>
        <h5 class="text-primary">
          <b>Inicio de Sesión</b>
        </h5>
      </div>
      <div class="login-form mt-4" style="overflow: hidden;">
        <iframe src="https://app.condominiosvenezuela.com/session/signin" frameborder="0" class="iframe-login"></iframe>
        <!-- <form>
          <div class="form-group">
            <label for="exampleInputEmail1">Usuario</label>
            <input
              type="text"
              class="form-control"
              id="cvUsername"
            />
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1">Password</label>
            <input
              type="password"
              class="form-control"
              id="cvPassword"
            />
          </div>
          <a href="javascript: void(0);" class="float-right text-primary font-size-15">¿Olvidaste tu Password.?</a>
          <div class="custom-control custom-checkbox col-sm-6">
            <input type="checkbox" class="custom-control-input" id="customCheck1" />
            <label class="custom-control-label font-size-15" for="customCheck1">Recuerdame</label>
          </div>
          <div class="text-center mt-4">
            <button type="submit" class="btn btn-primary">
              Login
              <arrow-right-circle-icon class="icon-size-15 icon ml-1"></arrow-right-circle-icon>
            </button>
          </div>
        </form> -->
        <!-- <div class="text-center" style="padding-top: 30px;">
        </div> -->
      </div>
    <div class="footer__animation">
        <img src="@/assets/images/footer__home___illustration.png" alt="Home" class="home__illustration">
    </div>
    </b-modal>

    <!-- <b-modal id="register-modal" hide-header hide-footer centered>
      <div class="text-center">
        <h3 class="title mb-4">Bienvenido a condominiosvenezuela</h3>
        <h4 class="text-uppercase text-primary">
          <b>Registro</b>
        </h4>
      </div>
      <div class="login-form mt-4">
        <form>
          <div class="form-group">
            <label for="exampleInputUsername">Nombre</label>
            <input
              type="text"
              class="form-control"
              id="exampleInputUsername"
              placeholder="Escribe Nombre y Apellido"
            />
          </div>
          <div class="form-group">
            <label for="exampleInputEmail2">Email</label>
            <input
              type="email"
              class="form-control"
              id="exampleInputEmail2"
              placeholder="Youremail@email.com"
            />
          </div>
          <div class="form-group">
            <label for="exampleInputPassword2">Password</label>
            <input
              type="password"
              class="form-control"
              id="exampleInputPassword2"
              placeholder="Password"
            />
          </div>
          <div class="form-group">
            <label for="exampleInputUsername">Referido Por</label>
            <input
              type="text"
              class="form-control"
              id="exampleInputReferido"
              placeholder="Código de quien te refirió"
            />
          </div>
          <a href="javascript: void(0);" class="float-right text-primary font-size-15">¿Olvidaste tu Password.?</a>
          <div class="custom-control custom-checkbox col-sm-6">
          </div>
          <div class="text-center mt-4">
            <button type="submit" class="btn btn-primary">
              Registrar
              <arrow-right-circle-icon class="icon-size-15 icon ml-1"></arrow-right-circle-icon>
            </button>
          </div>
        </form>
        <div class="text-center" style="padding-top: 30px;">
          <p class="text-muted mb-0">
            ¿Ya estas registrado?
            <a href="javascript: void(0);" class="text-primary">Login aquí</a>
          </p>
        </div>
      </div>
    </b-modal> -->
  </nav>
  <!-- Navbar End -->
</template>

