<script>
import {
  LayoutIcon,
  LifeBuoyIcon,
  ServerIcon,
  RssIcon
} from "vue-feather-icons";
export default {
  components: { LayoutIcon, LifeBuoyIcon, ServerIcon, RssIcon }
};
</script>

<template>
  <div>
    <!-- Nosotros Start -->
    <section
      class="section bg-light feather-bg-img"
      :style="{'background-image': 'url(' + require('@/assets/images/features-bg-img-1.png') + ')'}"
      id="nosotros"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="text-center mb-5">
              <h3 class="title mb-3">Acerca de Nosotros</h3>
              <p class="text-secondary font-size-15">
                <b>www.condominiosvenezuela.com</b> es una aplicación <b>100% en línea</b> diseñada para la administración de condominios y/o edificios de diversas indoles (Residenciales, Centros Comerciales, Empresariales, etc). Es la herramienta ideal para juntas de condominios autoadministradas o empresas de servicios administrativos.
              </p>
            </div>
          </div>
        </div>

        <div class="row align-items-center">
          <div class="col-lg-5">
            <p class="font-weight-medium text-uppercase mb-2">
              <i class="mdi mdi-chart-bubble h2 text-primary mr-1 align-middle"></i> Ahorra tiempo y esfuerzo.
            </p>
            <h3 class="font-weight-semibold line-height-1_4 mb-4">
                Con <b>Condominios Venezuela</b>, podrás administrar tu edificio o condominio en una plataforma fácil de usar. Olvida los largos procesos y dedica tiempo a otras tareas importantes.
            </h3>
            <!-- <h3 class="font-weight-semibold line-height-1_4 mb-4">Build <b>community</b> & <b>conversion</b> with our suite of <b>social tool</b></h3> -->
            <p class="text-secondary font-size-15 mb-4">
                Nuestro software le permitirá administrar su(s) condominio(s) en una forma fácil y eficiente, además de brindar a los propietarios o residentes de cada condominio las facilidades para consultar su estatus de solvencia en cuanto a los pagos y la manera en como los recursos del condominio son gestionados, esto proporciona transparencia y confianza en la gestión de la junta administradora.
            </p>
            <!-- <p class="text-muted mb-2">
              <server-icon class="icon-xs mr-1"></server-icon>Donec pede justo fringilla vel nec.
            </p> -->
            <!-- <p class="text-muted">
              <rss-icon class="icon-xs mr-1"></rss-icon>Cras ultricies mi eu turpis hendrerit fringilla.
            </p> -->
            <!-- <div class="mt-5">
              <a href="javascript: void(0);" class="btn btn-primary mr-2">Read More</a>
              <a href="javascript: void(0);" class="btn btn-soft-primary">Buy Now</a>
            </div> -->
          </div>
          <div class="col-lg-6 offset-lg-1">
            <div class="mt-4 mt-lg-0">
              <img src="@/assets/images/features-img-1.png" alt class="img-fluid d-block mx-auto" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section
      class="section bg-light feather-bg-img"
      :style="{'background-image': 'url(' + require('@/assets/images/features-bg-img-1.png') + ')'}"
    >
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="mb-4 mb-lg-0">
              <img src="@/assets/images/features-img.png" alt class="img-fluid d-block mx-auto" />
            </div>
          </div>

          <div class="col-lg-5 offset-lg-1">
            <p class="font-weight-medium text-uppercase mb-2">
              <i class="mdi mdi-chart-bubble h2 text-primary mr-1 align-middle"></i> Invierte de forma Creativa
            </p>
            <h3 class="font-weight-semibold line-height-1_4 mb-4">
              <b>Condominios Venezuela</b> es una empresa con <b>capital privado</b> y debidamente constituida.
            </h3>
            <p class="text-muted font-size-15 mb-4">
              Condominios Venezuela permite rentar <b>USDT</b> (Moneda estable con el mismo valor del dólar USD) y obtener intereses diariamente a través
              de su plataforma. Comparte con un grupo empresarial de tecnología y se dedican a crear soluciones para la economía descentralizada. 
              <br/>Condominios Venezuela cuenta con un extraordinario equipo de 
              profesionales comprometidos con la descentralización del 
              comercio electrónico y la libertad financiera, dedicados a 
              aprovechar las oportunidades que ofrecen los sistema 
              financieros, utilizando tecnologías de última generación que 
              facilitan el intercambio comercial y les permite obtener 
              excelentes resultados en cada negociación.
            </p>
            <p class="text-muted mb-2">
              <layout-icon class="icon-xs mr-1"></layout-icon>Donec pede justo fringilla vel nec.
            </p>
            <p class="text-muted">
              <life-buoy-icon class="icon-xs mr-1"></life-buoy-icon>Cras ultricies mi eu turpis hendrerit fringilla.
            </p>
            <div class="mt-5">
              <a href="javascript: void(0);" class="btn btn-primary mr-2">Read More</a>
              <a href="javascript: void(0);" class="btn btn-soft-primary">Buy Now</a>
            </div>
          </div>          
        </div>
      </div>
    </section> -->
    <!-- Nosotros End -->
  </div>
</template>